$font-family-base: 'LatoWeb', sans-serif;
$font-family-light: 'LatoWebLight', sans-serif;
$font-family-regular: $font-family-base;
$font-family-medium: 'LatoWebMedium', sans-serif;
$font-family-bold: 'LatoWebBold', sans-serif;
$font-size: 14px;
$line-height: 14px;
$padding-base: 20px;
$border-radius: 10px;

$marksFontFamily: cursive;

/* page header */
$header-shadow: rgba(204, 204, 204, .25);
$header-height: 80px;
$new-header-height: 64px;
$new-header-line-two-height: 48px;

$modal-ground: #00000087;

$mobile-header-height: 60px;
$mobile-footer-height: 70px;

/* page right sidebar */
$right-sidebar-width: 290px;
$right-sidebar-widget-border-radius: $border-radius;
$right-sidebar-widget-shadow: 0 0 10px rgba(0, 0, 0, .1);
$right-sidebar-widget-active-shadow: 0 0 15px rgba(0, 0, 0, .1);
$right-sidebar-widget-h-spacing: 30px;
$right-sidebar-widget-v-spacing: 30px;

/* page content */
$content-padding: 20px; /* отступы внутри */
$content-v-spacing: 20px; /* отступ снаружи по вертикали */
$content-h-spacing: 30px; /* отступ снаружи по горизонтали */
$content-border-radius: $border-radius;

/* animation */
$transition-time: 200ms;
$fast-transition-time: 100ms;
$slow-transition-time: 400ms;

/* buttons */
$btn-border-radius: 10px;
$btn-primary: #87D37C;
$btn-danger: #E86F79;

/* Forms */
$input-border: 1px solid #EEEEEE;
$input-border-radius: 10px;
$input-text-color: #000000;
$input-placeholder-color: #a4b0c3;


/* Action */
$selected-element-border: 1px solid #5AB3E4;

// popover
// @see https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss
$zindex-popover:                    1060 !default;

// Popovers

$popover-font-size:                 12px;
$popover-max-width:                 276px !default;
$popover-border-width:              0;
$popover-border-color:              transparent;
$popover-border-radius:             5px;
$popover-inner-border-radius:       5px; // subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                0px 0px 10px rgba(0, 0, 0, 0.1);

$popover-header-bg:                 darken(#FFFFFF, 3%) !default;
$popover-header-color:              #1E252B;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          .75rem !default;

$popover-body-color:                #1E252B;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               #FFFFFF !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;


// Responsive
$min-width:     320px;
$breakpoint-xs: 320px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1200px;

/**
  OnClass UIKit
  Тени для карточек
**/

$shadow-card: 0 0 10px #0000001A;

/**
  OnClass UIKit
  Палитра цветов
**/

$white: #FFFFFF;
$gray00: #F5F8FA;
$gray01: #F6F6F6;
$gray02: #EEEEEE;
$gray03: #CCCCCC;
$gray04: #A4B0C3;
$gray05: #6D7381;
$black: #1E252B;

$sky: #5AB3E4;
$sky-darker: #41A1D9;
$sky-hover: #3491C3;
$sky-play: #0C6495;

$light-green: #87D37C;
$light-green-hover: #65C558;

$light-sun: #FEF4AE;
$sunlight: #FEED7D;
$hot-sun: #FFDD00;

$tomato: #E86F79;
$tomato-hover: #C84550;

$purple: #CBBEFF;
$purple-hover: #623BFC;

$ice: #E6F6FF;

$skeleton: #EFF1F6;

/** @see functions/assetsUrl */
$assets_host: '';

@import "variables.override";
